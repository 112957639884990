import { addCommasToPrice } from "../../helpers/conversions";

const Contract = ({ properties }) => {
  return (
    <div className="space-y-5 p-4">
      <div className="col-span-3 grid-cols-3 grid  gap-5">
        {properties?.escrow && (
          <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
            <p>Escrow</p>
            <h4 className="text-19 font-medium">
              ${addCommasToPrice(properties?.escrow)}
            </h4>
          </div>
        )}
        {/* {properties?.cso && ( */}
        {/*   <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md"> */}
        {/*     <p className="mb-[1px] text-sm">CSO</p> */}
        {/*     <h4 className="text-19 font-medium">{properties?.cso}</h4> */}
        {/*   </div> */}
        {/* )} */}
        {/* {properties?.cso_type && ( */}
        {/*   <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md"> */}
        {/*     <p className="mb-[1px] text-sm">CSO Type</p> */}
        {/*     <h4 className="text-19 font-medium">{properties?.cso_type}</h4> */}
        {/*   </div> */}
        {/* )} */}
        {properties?.owners_name && (
          <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
            <p className="mb-[1px] text-sm">Owners Name</p>
            <h4 className="text-19 font-medium">{properties?.owners_name}</h4>
          </div>
        )}
        {properties?.owners_2_name && (
          <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
            <p className="mb-[1px] text-sm">Owners 2 Name</p>
            <h4 className="text-19 font-medium">{properties?.owners_2_name}</h4>
          </div>
        )}
        {properties?.listing_date && (
          <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
            <p className="mb-[1px] text-sm">Listing Date</p>
            <h4 className="text-19 font-medium">{properties?.listing_date}</h4>
          </div>
        )}

        {/* {properties?.expiration_date && ( */}
        {/*   <span className="flex items-center gap-2"> */}
        {/*     <h4 className="text-19">Expiration Date -</h4> */}
        {/*     <p>{properties?.expiration_date}</p> */}
        {/*   </span> */}
        {/* )} */}
      </div>

      <div className="col-span-1 w-full md:col-span-2 lg:col-span-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {properties?.proposedFinancing &&
          properties?.proposedFinancing.length > 0 && (
            <div className="space-y-2">
              <h3 className="text-19 font-medium">Proposed Financing</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.proposedFinancing.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
        {properties?.possession && properties?.possession.length > 0 && (
          <div className="space-y-2">
            <h3 className="text-19 font-medium">Possession</h3>
            <hr />
            <ul className="text-sm list-disc pl-4">
              {properties?.possession.map((feature) => (
                <li>{feature}</li>
              ))}
            </ul>{" "}
          </div>
        )}
        {properties?.showingInstructions &&
          properties?.showingInstructions.length > 0 && (
            <div className="space-y-2 ">
              <h3 className="text-19 font-medium">Showing Instructions</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.showingInstructions.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
      </div>
    </div>
  );
};

export default Contract;
